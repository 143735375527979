if(module.hot) {
    require('../styles/app.scss');
}

jQuery(document).ready(function () {
    const $cartModals = jQuery('#modalEmptyCart, #modalRemoveItem');

    $cartModals.each(function () {
        const $form = jQuery(this).closest('form');

        if($form) {
            $form.appendTo('body');
        }
    });
});
